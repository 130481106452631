<template>
  <div>
    <template v-if="sepet.k_no && sepet.sepet_urunler.length">
      <b-overlay :show="show" spinner-variant="primary" spinner-type="grow">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col cols="12" md="8">
                <SepetUrunleri :sepet="sepet" />
                <MisafirBilgileri class="mt-3" :sepet="sepet" />
                <RezervasyonNotu class="mt-3" :sepet="sepet" />
                <!-- <HotelBilgisi class="mt-3" :sepet="sepet" /> -->
              </b-col>
              <b-col cols="12" md="4">
                <SepetDetayInfo :rezervasyon="sepet" />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </template>
    <template v-else>
      <b-row>
        <b-col cols="12">
          <b-alert :show="true" variant="warning" class="text-center">
            <h1>UYARI !!</h1>
            <p>Sepette Gösterilecek Ürün Bulunmamaktadır</p>
            <router-link to="/rezervasyon/urunler" class="text-body"> Yeni Ürün Eklemek için Tıkla</router-link>
          </b-alert>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { ref, computed, defineComponent } from '@vue/composition-api';
import SepetUrunleri from './component/guncelle/SepetUrunleri.vue';
import MisafirBilgileri from './component/sepet/MisafirBilgileri.vue';
import RezervasyonNotu from './component/sepet/RezervasyonNotu.vue';
import OdemeBilgisi from './component/sepet/OdemeBilgisi.vue';
import SepetDetayInfo from './component/guncelle/SepetDetayInfo.vue';
import HotelBilgisi from './component/guncelle/HotelBilgisi.vue';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: { SepetUrunleri, MisafirBilgileri, RezervasyonNotu, OdemeBilgisi, SepetDetayInfo, HotelBilgisi },
  setup(_, context) {
    const expo = {};
    const { router } = useRouter();
    const toast = useToast();
    expo.show = ref(false);
    expo.sepet = computed(() => store.getters.getDuzenleRezervasyon);

    expo.onSubmit = () => {
      context.emit('show', true);

      store.dispatch('rezervasyonGuncelle', expo.sepet.value).then((res) => {
        if (res.data.success) {
          toast.success('Rezervasyon Güncelleme Başarılı.', { position: 'bottom-left' });
          store.commit('SET_SEPET_DUZENLE_REZERVASYON_RESET');
          router.push({ name: 'rezervasyon-detay', params: { k_no: res.data.data.k_no } });
          context.emit('show', false);
        } else {
          toast.warning(res.data.message);
          context.emit('show', false);
        }
      });
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
