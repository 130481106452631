<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold"> <i class="fad fa-hotel" /> Hotel Bilgisi </b-card-header>
    <b-card-text class="p-2">
      <b-row>
        <b-col cols="12" md="6" class="py-2">
          <validation-provider name="Hotel Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Hotel Adı">
              <b-form-input
                class="rounded-0"
                v-model="sepet.hotel_bilgi.hotel_adi"
                size="lg"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6" class="py-2">
          <validation-provider name="Oda No" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Oda No">
              <b-form-input
                class="rounded-0"
                v-model="sepet.hotel_bilgi.oda_no"
                size="lg"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12" md="6">
          <validation-provider name="Şehir" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Şehir">
              <v-select
                v-model="sepet.hotel_bilgi.sehir"
                :options="sehirler"
                :reduce="(bolge) => bolge"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg w-100 ml-1"
                aria-describedby="input-1-live-feedback"
                :filterable="false"
                :state="getValidationState(validationContext)"
                @input="handlerSelectedSehir($event)"
              >
                <template slot="selected-option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="İlçe" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="İlçe">
              <v-select
                v-model="sepet.hotel_bilgi.ilce"
                :options="ilceler"
                :reduce="(bolge) => bolge"
                label="title"
                :clearable="true"
                class="invoice-filter-select d-block select-size-lg w-100 ml-1"
                aria-describedby="input-1-live-feedback"
                :state="getValidationState(validationContext)"
                :filterable="false"
                :disable="!ilceler.length"
                @input="handlerSelectedIlce($event)"
              >
                <template slot="selected-option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.icerik[defaultDil].baslik }}
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import { ref, computed, defineComponent, onMounted, toRefs } from '@vue/composition-api';
import store from '@/store';
import axiosIns from '@/libs/axios';
import vSelect from 'vue-select';

export default defineComponent({
  components: { vSelect },
  props: { sepet: { type: Object, required: true } },
  setup(props) {
    const expo = {};
    const { sepet } = toRefs(props);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.sehirler = computed(() =>
      store.getters.getTumBolgeler.filter((x) => x.bolge_k_no == null && x.urun_grubu.includes('tur'))
    );
    expo.ilceler = ref([]);

    expo.handlerSelectedSehir = (event) => {
      if (event != undefined) {
        const ilce = store.getters.getTumBolgeler.filter((x) => x.bolge_k_no == event.k_no && x.urun_grubu.includes('tur'));
        expo.ilceler.value = ilce;
      }
    };

    expo.handlerSelectedIlce = (event) => {
      if (event != undefined) {
        axiosIns
          .post('/travel/urun-bolge-alis-saat-getir', { bolge_k_no: event.k_no, product: sepet.value.sepet_urunler })
          .then((res) => {
            if (res.data.success) {
              const urunler = res.data.data;

              urunler.forEach((el) => {
                const urun = el[0];
                if (urun != undefined) {
                  if (urun.k_no != null && urun.bolge_alis) {
                    const index = sepet.value.sepet_urunler.findIndex((x) => x.urun_k_no == urun.k_no);

                    if (urun.bolge_alis.length > 0) {
                      sepet.value.sepet_urunler[index].bolge_alis_saati = urun.bolge_alis[0].sure;
                    }

                    store.commit('SET_DUZENLE_REZERVASYON', sepet.value);
                  }
                }
              });
            }
          });
      }
    };

    onMounted(() => {
      expo.handlerSelectedSehir();
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
